import { useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { useTranslation } from '@/hooks/translations';
import { Input, PrimaryButton } from '@/components/common';
import AuthService from '@/services/auth';
import { EnableMfaResponseData, LoginResponse } from '@/interfaces/auth/reponse';
import { SocketConnector } from '@/contexts/socket';
import { clearLocalStorage } from '@/config/axios';
import { ILoginProps } from '.';

interface IEnableProps extends ILoginProps {
    loginResponse?: LoginResponse;
}

const Enable = ({
    loading,
    setLoading,
    setError,
    loginResponse,
    setPage,
    accessSelectRestaurant,
    loginAsAdmin,
}: IEnableProps) => {
    const authService = AuthService.getInstance();
    const { t } = useTranslation('common');
    const [qrPage, setQrPage] = useState<boolean>(false);
    const [response, setResponse] = useState<EnableMfaResponseData>();
    const [totp, setTotp] = useState<string>('');

    const loginResponseData = loginResponse?.data;

    const handleEnable = () => {
        setLoading(true);
        setError('');
        const accessToken = loginResponseData?.access_token || '';
        const token = loginResponseData?.token || '';

        authService
            .enableMfa({ token, accessToken, type: loginAsAdmin ? 'admin' : 'vendor' })
            .then((res: any) => {
                setResponse(res);
                setQrPage(true);
            })
            .catch((err: any) => setError(err.message))
            .finally(() => setLoading(false));
    };

    const handleCancel = () => {
        if (loginResponse) {
            accessSelectRestaurant(loginResponse);
        }
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        authService
            .verifyToken({
                id: loginResponseData?.userId || loginResponseData?.id || '',
                accessToken: loginResponseData?.access_token,
                challengeName: response?.challengeName || '',
                totp,
            })
            .then((res: any) => {
                if (res) {
                    SocketConnector.getInstance().destroy();
                    clearLocalStorage();
                    setPage?.('login');
                }
            })
            .catch((err: any) => setError(err.message))
            .finally(() => setLoading(false));
    };

    return !qrPage ? (
        <Grid container spacing={2}>
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <Typography>{t('Do you want to enable multi-factor authentication?')}</Typography>
            </Grid>
            <Grid item xs={12}>
                <PrimaryButton
                    id="login-btn"
                    disabled={loading}
                    type="submit"
                    text={t('Enable')}
                    fullWidth
                    styles={{
                        marginTop: '1rem',
                        fontWeight: 700,
                        fontSize: '1rem',
                        borderRadius: '0.5rem',
                    }}
                    onClick={handleEnable}
                />
            </Grid>
            <Grid item xs={12}>
                <Button
                    id="verify-button"
                    variant="contained"
                    size="large"
                    fullWidth
                    color="inherit"
                    onClick={handleCancel}
                >
                    {t('Cancel')}
                </Button>
            </Grid>
        </Grid>
    ) : (
        <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                    <img alt={response?.qrCode} src={response?.qrCode} />
                </Grid>
                <Grid item xs={12}>
                    <Input
                        id="totp"
                        onChange={(e) => setTotp(e.target.value)}
                        value={totp}
                        name="totp"
                        type="text"
                        label={t('Authentication Code')}
                        fullWidth
                        margin="normal"
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <PrimaryButton
                        id="login-btn"
                        disabled={loading}
                        type="submit"
                        text={t('Verify')}
                        fullWidth
                        styles={{
                            marginTop: '1rem',
                            fontWeight: 700,
                            fontSize: '1rem',
                            borderRadius: '0.5rem',
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography>
                        {t(
                            'Open the two-factor authenticator (TOTP) app on your mobile device to view your authentication code',
                        )}
                    </Typography>
                </Grid>
            </Grid>
        </form>
    );
};

export default Enable;
