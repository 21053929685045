import { useState } from 'react';
import { Grid, Typography } from '@mui/material';

import { Input, PrimaryButton } from '@/components/common';
import { useTranslation } from '@/hooks/translations';
import AuthService from '@/services/auth';
import { AuthUserData } from '@/interfaces/auth/reponse';
import { ILoginProps } from '.';

interface IMfaProps extends ILoginProps {
    loginResponseData?: AuthUserData;
}

const Mfa = ({ loginResponseData, accessSelectRestaurant, setError, setLoading, loading }: IMfaProps) => {
    const { t } = useTranslation('common');
    const authService = AuthService.getInstance();

    const [totp, setTotp] = useState<string>('');

    const handleSubmit = (e: any) => {
        e.preventDefault();
        setLoading(true);
        authService
            .verifyToken({
                id: loginResponseData?.userId || loginResponseData?.id || '',
                sessionId: loginResponseData?.cognitoUser?.Session,
                challengeName: 'SOFTWARE_TOKEN_MFA',
                totp,
            })
            .then((res: any) => {
                accessSelectRestaurant(res);
            })
            .catch((err: any) => {
                setError(err.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Input
                        id="totp"
                        onChange={(e) => setTotp(e.target.value)}
                        value={totp}
                        name="totp"
                        type="text"
                        label={t('Authentication Code')}
                        fullWidth
                        margin="normal"
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <PrimaryButton
                        id="login-btn"
                        disabled={loading}
                        type="submit"
                        text={t('Verify')}
                        fullWidth
                        styles={{
                            marginTop: '1rem',
                            fontWeight: 700,
                            fontSize: '1rem',
                            borderRadius: '0.5rem',
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography>
                        {t(
                            'Open the two-factor authenticator (TOTP) app on your mobile device to view your authentication code',
                        )}
                    </Typography>
                </Grid>
            </Grid>
        </form>
    );
};

export default Mfa;
