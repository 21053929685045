import { useState } from 'react';
import { useRouter } from 'next/router';
import * as Yup from 'yup';
import { type FormikHelpers, useFormik, FormikProvider } from 'formik';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import AuthService from '@/services/auth';
import { useTranslation } from '@/hooks/translations';
import { routes } from '@/constants/routes';
import { capitalSmallNumberSpecialCharacter } from '@/constants/regexes';
import { PrimaryButton } from '@/components/common';
import FormikPasswordField from '@/components/Formik/FormikPasswordField';
import FormikTextField from '@/components/Formik/FormikTextField';
import LinkButton from '@/components/LinkButton';

import styles from './index.module.scss';

export interface ResetPasswordFormData {
    email: string;
    password: string;
    password_confirmation: string;
    code: string;
}

export default function verify() {
    const authService = AuthService.getInstance();
    const { t } = useTranslation('common');
    const router = useRouter();
    const [error, setError] = useState<string>('');
    const { email, lang } = router.query as { email?: string; lang?: string };

    const initialValues: ResetPasswordFormData = {
        email: email || '',
        password: '',
        password_confirmation: '',
        code: '',
    };

    const validationSchema = Yup.object({
        email: Yup.string().email(t('Please enter a valid email')).required(t('The email field is required!')),
        password: Yup.string()
            .matches(
                capitalSmallNumberSpecialCharacter,
                t('1 capital case 1 small case 1 number 1 special character must be there'),
            )
            .min(9, t('The password must be of min 9 characters'))
            .required(t('The password field is required!')),
        password_confirmation: Yup.string()
            .oneOf([Yup.ref('password'), null], t("The confirm password doesn't match"))
            .required(t('The password field is required!')),
        code: Yup.string().required(t('The verification code field is required!')),
    });

    const formik = useFormik<ResetPasswordFormData>({
        enableReinitialize: true,
        initialValues,
        validationSchema,
        onSubmit: (values: ResetPasswordFormData, { setSubmitting }: FormikHelpers<ResetPasswordFormData>) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { password_confirmation, ...body } = values;
            setSubmitting(true);
            authService
                .resetPasswordVerify(body)
                .then((res) => res.data)
                .then(() => {
                    router.push({ pathname: routes.LOGIN, query: { lang } });
                })
                .catch((e) => {
                    let message = t('Something went wrong');
                    if (e.message === 'USER_NOT_FOUND') message = t('User not found');
                    if (e.message === 'INVALID_VERIFICATION_CODE') {
                        message = t('You have entered an incorrect verification code');
                    }
                    setError(message);
                })
                .finally(() => {
                    setSubmitting(false);
                });
        },
    });

    return (
        <Box className={styles.container}>
            <Card className={styles.verifyCard}>
                <CardContent>
                    <FormikProvider value={formik}>
                        <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                            <Typography color="primary" className={styles.headerText} variant="h6">
                                {t('QlubPay Reset Password')}
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FormikTextField id="email-field" name="email" label={t('Email')} type="email" />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormikPasswordField
                                        id="password"
                                        name="password"
                                        label={t('New Password')}
                                        requirements={{
                                            min: 9,
                                            number: true,
                                            smallcase: true,
                                            uppercase: true,
                                            specialCharacter: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormikPasswordField
                                        id="cpassword"
                                        name="password_confirmation"
                                        label={t('Confirm Password')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormikPasswordField id="code-field" name="code" label={t('Verification Code')} />
                                </Grid>
                                {error && (
                                    <Grid item xs={12}>
                                        <Typography textAlign="center" color="error">
                                            {error}
                                        </Typography>
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <PrimaryButton
                                        id="login-btn"
                                        type="submit"
                                        text={t('Submit')}
                                        fullWidth
                                        styles={{
                                            fontWeight: 700,
                                            fontSize: '1rem',
                                            borderRadius: '0.5rem',
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item className={styles.backtoLoginLink}>
                                <LinkButton pathname={routes.LOGIN} text={t('Back to Login')} />
                            </Grid>
                        </form>
                    </FormikProvider>
                </CardContent>
            </Card>
        </Box>
    );
}
